<template>
    <div>
        <div class="page-filter-box">
            <el-row :gutter="20">
                <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="4">
                    <el-input placeholder="商家名称/手机" v-model="params.business" size="small"></el-input>
                </el-col>
                <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="4">
                    <el-input placeholder="客户名称/手机" v-model="params.customer" size="small"></el-input>
                </el-col>
                <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="4">
                    <el-select v-model="params.sort" size="small">
                        <el-option :value="1" label="按商家名称排序"></el-option>
                        <el-option :value="2" label="按客户名称排序"></el-option>
                    </el-select>
                </el-col>
                <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="4" class="page-filter-btns">
                    <el-button
                        type="primary"
                        icon="el-icon-search"
                        @click="search"
                        size="small"
                        >查询</el-button
                    >
                    <el-button
                        type="default"
                        icon="el-icon-refresh"
                        @click="reset"
                        size="small"
                        >重置</el-button
                    >
                </el-col>
            </el-row>
        </div>
        <!-- <div class="page-btns-box">
            <el-button type="primary" plain icon="el-icon-plus" @click="add" size="small">新增讣告</el-button>
        </div> -->
        <div class="page-list-box">
            <el-table
                :data="dataList"
                border
                style="width: 100%"
                v-loading="listLoading"
                size="mini"
                stripe
            >   
                <el-table-column prop="title" label="标题" show-overflow-tooltip min-width="150"></el-table-column>
                <el-table-column prop="customer" label="客户名称" show-overflow-tooltip></el-table-column>
                <el-table-column prop="customerFamily" label="客户家属" show-overflow-tooltip></el-table-column>
                <el-table-column prop="business" label="商家名称" show-overflow-tooltip></el-table-column>
                <el-table-column prop="lightCount" label="点灯数"></el-table-column>
                <el-table-column prop="lightMoney" label="点灯总金额（元）" min-width="120"></el-table-column>
                <el-table-column prop="supportCount" label="送礼数"></el-table-column>
                <el-table-column prop="supportMoney" label="送礼总金额（元）" min-width="120"></el-table-column>
                <el-table-column prop="reviewCount" label="评论数"></el-table-column>
                <el-table-column prop="viewCount" label="浏览数"></el-table-column>
                <el-table-column prop="status" label="状态"></el-table-column>
                <el-table-column prop="createTime" label="创建时间" min-width="150"></el-table-column>
                <el-table-column fixed="right" label="操作" width="140">
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="edit(scope.row.id)"
                            >编辑</el-button
                        >
                        <el-button
                            v-if="scope.row.status != '展示中'"
                            type="text"
                            size="small"
                            @click="preview(scope.row.id)"
                            >预览</el-button
                        >
                        <el-button
                            v-if="scope.row.status == '展示中'"
                            type="text"
                            size="small"
                            @click="$refs.codeView.show(scope.row)"
                            >分享</el-button
                        >
                        <el-button
                            type="text"
                            size="small"
                            @click="review(scope.row.id)"
                            >评论</el-button
                        >
                        
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="page-pagination-box clearfix">
            <el-pagination
                v-if="data"
                background
                class="fr"
                @size-change="handleSizeChange"
                @current-change="getDataList"
                :current-page.sync="params.pn"
                :page-sizes="[15,30,50]"
                :page-size.sync="params.ps"
                layout="total, sizes, prev, pager, next"
                :total="data.totalCount || 0">
            </el-pagination>
        </div>

        <h5-preview ref="h5Preview"></h5-preview>
        <code-view ref="codeView"></code-view>
    </div>
</template>
<script>
import H5Preview from '@/components/H5Preview'; 
import CodeView from '@/components/CodeView';
const defaultParams = {
    pn:1,
    business: "",
    customer: "",
    sort: 1,
    ps:15,
}
export default {
    components:{H5Preview,CodeView},
    data() {
        return {
            data: null,
            dataList: null,
            params: {
                ...defaultParams,
                pn: parseInt(this.$route.query.pn || 1),
                business: this.$route.query.business || "",
                customer: this.$route.query.customer || "",
                sort: parseInt(this.$route.query.sort || 1),
                ps: parseInt(this.$route.query.ps || 15),
            },
            listLoading: true,
        };
    },
    computed:{
        
    },
    mounted() {
        this.getDataList();
    },
    methods: {
        getDataList() {
            this.listLoading = true;
            this.$request({
                url: "/api/ht/fugao/list",
                params: this.params,
            })
                .then((res) => {
                    const { state, errmsg, result } = res.data;
                    if (state && result) {
                        this.data = result;
                        this.dataList = result.list || [];
                    } else {
                        this.$message.error(errmsg || "请求失败");
                    }
                })
                .catch(() => {
                    this.$message.error("请求错误");
                })
                .finally(() => {
                    this.listLoading = false;
                });
        },
        //选择pageSize
        handleSizeChange(e) {
            this.params.pn = 1;
            this.params.ps = e;
            this.getDataList();
        },
        //搜索
        search() {
            this.params.pn = 1;
            this.getDataList();
        },
        //重置搜索
        reset() {
            this.params = {
                ...defaultParams,
                ps: this.params.ps,
            };
            this.getDataList();
        },
        //编辑
        edit(id) {
            this.$router.push({
                path: `/fugao/edit`,
                query: {
                    redirect: this.$utils.objArgs(
                        this.$router.currentRoute.path,
                        {
                            ...this.params,
                        }
                    ),
                    fugaoId: id
                },
            });
        },
        add(){
            this.$router.push({
                path: `/fugao/add`
            });
        },
        //评论
        review(id) {
            this.$router.push({
                path: `/fugao/review/${id}`,
                query: {
                    redirect: this.$utils.objArgs(
                        this.$router.currentRoute.path,
                        {
                            ...this.params,
                        }
                    ),
                },
            });
        },
        //预览
        preview(id){
            this.btnLoading = true;
            this.$request({
                url: "/api/ht/fugao/previewurl",
                method: "POST",
                params: {
                    fugaoId: id,
                },
            })
                .then((res) => {
                    //处理成功回调
                    const { state, msg, result } = res.data;
                    if (state && state == 1) {
                        this.$refs.h5Preview.show(result);
                    } else {
                        return this.$message.error(msg || "操作失败");
                    }
                })
                .catch(() => {
                    this.$message.error("操作失败,请重试");
                })
                .finally(() => {
                    this.btnLoading = false;
                });
        }
    },
};
</script>