<template>
    <div>
        <el-drawer
            title="预览"
            :visible.sync="drawerShow"
            :wrapperClosable="true"
            :destroy-on-close="true"
            size="auto"
            @close="close"
        >
            <div class="drawer-content">
                <template v-if="codeData">
                    <h2 class="name">{{codeData.title}}</h2>
                    <div class="code">
                        <vue-qr :text="codeData.url" :size="800" :logoMargin="8" logoBackgroundColor="#ffffff" :callback="getImage" :margin="0"></vue-qr>
                        <input class="copyTool" ref="copyTool" :value="codeData.url" />
                    </div>
                    <div class="tips color-info">使用微信扫一扫浏览</div>
                    <div class="MT10">OR</div>
                    <div class="MT10"> 
                        <el-button size="small" plain type="primary" @click="openUrl(codeData.url)">直接打开</el-button>
                    </div>
                </template>
            </div>
            <div class="drawer-footer">
                <el-button type="primary" size="medium" @click="copy">复制链接</el-button>
                <el-button type="primary" size="medium" @click="downloadCode">下载二维码</el-button>
            </div>
        </el-drawer>
    </div>
</template>

<script>
import VueQr from 'vue-qr'
export default {
    components:{VueQr},
    data(){
        return{
            drawerShow: false,
            codeData:null,
            codeImage:'',
            copyTimer: null,
            h5url: "https://m.xinanshengming.cn"
        }
    },
    methods:{
        close(){
            this.drawerShow = false
        },
        show(obj){
            this.codeData = {
                ...obj,
                title: obj.customer + '的讣告',
                url: `${this.h5url}/pages/fugao?id=${obj.id}`,
            };

            this.drawerShow = true
        },
        getImage(url){
            this.codeImage = url;
        },
        downloadCode(){
            const _data = this.$utils.base64ToBlob(this.codeImage);
            this.$utils.exportFile(_data,`${this.codeData.title}.png`);
        },
        //复制链接
        copy(){
            clearTimeout(this.copyTimer);
            this.copyTimer = setTimeout(() => {
                const e = this.$refs.copyTool;
                e.select();
                document.execCommand("Copy"); // 执行浏览器复制命令
                this.$message.success("已复制成功，可粘贴");
            },300)
        },
        //直接打开链接
        openUrl(url){
            window.open(url)
        }
    }
}
</script>

<style lang="scss" scoped>
.drawer-content{
    text-align: center;
    .name{
        font-size: 14px;
        line-height: 20px;
    }
    .code{
        width: 200px;
        height: 200px;
        margin: 15px auto;
        img{
            width: 100%;
            max-width: 100%;
        }
    }
}
.copyTool{
    opacity: 0;
    filter: alpha(opacity=0);
    // height: 1px;
}
</style>