<template>
    <el-drawer
        title="预览"
        :visible.sync="drawerShow"
        :destroy-on-close="true"
        size="414px"
        @close="close"
        append-to-body
    >
        <iframe :src="url" frameborder="0" height="100%" width="100%" sroll></iframe>
    </el-drawer>
</template>

<script>

export default {
    data(){
        return{
            drawerShow: false,
            url:'',
        }
    },
    methods:{
        close() {
            this.drawerShow = false;
        },
        show(url) {
            this.url =  url;
            this.drawerShow = true;
        },
    }
}
</script>